<script setup lang="ts">
  import type { PloneDocument, PloneSortOption } from '~/types/Plone'
  import { useToggle } from '@vueuse/core'

  const props = withDefaults(defineProps<{
    data: PloneDocument
    countLabel?: string
    loading?: boolean
    columns?: number
    perPage?: number
    subviewLinksMode?: 'radio' | 'buttons'
  }>(), {
    columns: 3,
    perPage: 24,
    subviewLinksMode: 'radio',
  })

  const route = useRoute()

  const { schema } = usePloneFilterSchema(props.data)
  const view = computed(() => props.data['@id'].endsWith('@map') ? 'map' : 'list')

  const [isFilterVisible, toggleFilter] = useToggle(false)

  const hasImages = view.value !== 'map' && props.data?.items?.some(({ image }) => !!image)
</script>


<template>
  <div :class="['app-dataset', { 'app-dataset--map': view === 'map' }]">
    <app-container :full-width="view === 'map'">
      <app-layout :full-width="view === 'map'">
        <template #aside>
          <app-card
            v-if="data.view_modes || data.subview_links || schema"
            class="app-dataset__panel">
            <section v-if="data.view_modes">
              <h3 class="u-fs--200 u-ff--mono u-mb--300">
                Zobrazit jako
              </h3>
              <button-group class="u-mb--400">
                <app-button
                  v-for="mode in data.view_modes"
                  :primary="mode.active"
                  :to="{
                    path: mode['@id'],
                    query: {
                      ...route.query,
                    },
                    }">
                  {{ mode.title }}
                </app-button>
              </button-group>
            </section>

            <app-button
              @click="toggleFilter"
              :class="[ 'app-dataset__filter-toggle', {
                'u-mb--400': isFilterVisible
              }]"
              block>
              Filtr
              <template #icon>
                <mdicon :name="isFilterVisible ? 'close' : 'chevron-down'"/>
              </template>
            </app-button>

            <div
              class="app-dataset__filter"
              v-show="isFilterVisible">
              <section v-if="data.subview_links">
                <ul
                  v-if="subviewLinksMode === 'radio'"
                  class="u-list-unstyled u-mb--400">
                  <li
                    v-for="link in data.subview_links"
                    class="u-mb--300">
                    <radio-link :to="link['@id']">
                      {{ link.title }}
                    </radio-link>
                  </li>
                </ul>
                <button-group
                  v-if="subviewLinksMode === 'buttons'"
                  class="u-mb--400">
                  <app-button
                    v-for="link in data.subview_links"
                    :primary="link.active"
                    :to="link['@id']">
                    {{ link.title }}
                  </app-button>
                </button-group>
              </section>

              <section v-if="schema">
                <app-form
                  :schema="schema"
                  :actions="false"
                  :reset-params="['page']"
                  route-query/>
              </section>
            </div>
          </app-card>
        </template>

        <sort-panel
          v-if="data.sorting_options && data.sorting_options.length > 0 && view === 'list'"
          class="u-mb--400"
          :data="data"
          :label="countLabel"/>

        <filter-overview
          v-if="schema"
          :schema="schema"
          :elevated="view === 'map'"
          class="app-dataset__filter-overview u-mb--400"/>

        <slot
          name="header"
          :view="view"
          :data="data"/>

        <div
          v-if="view === 'map'"
          class="app-dataset__map-container">
          <app-map
            class="app-dataset__map"
            :locations="data.items"/>
        </div>

        <div
          v-if="view === 'list'"
          class="app-dataset__list">
          <div class="app-dataset__list-items">
            <slot
              name="list"
              :view="view"
              :data="data">
              <template v-if="data.items && view === 'list'">
                <card-group
                  :align="hasImages ? 'end' : 'normal'"
                  match-height=".app-card__wrap"
                  class="u-mb--600"
                  :items="data.items"
                  :columns="columns"/>
              </template>
            </slot>
          </div>

          <client-only>
            <app-loading :loading="loading"/>
          </client-only>
        </div>

        <slot
          name="pagination"
          :data="data">
          <app-pagination
            v-if="view === 'list'"
            :total-items="data.items_total"
            :loading="loading"
            :per-page="perPage"/>
        </slot>
      </app-layout>
    </app-container>
  </div>
</template>

<style scoped>
  .app-dataset {
    position: relative;

    &:deep(.vue-map-container) {
      height: 100%;
    }
  }

  .app-dataset__list {
    position: relative;
  }

  .app-dataset__panel {
    position: relative;
    z-index: 100;
    margin-bottom: var(--gap-400);
  }

  .app-dataset__map-container {
    @media (--md) {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
    }
  }

  .app-dataset__map {
    height: calc(var(--vh-100, 100vh) - var(--nav-height))!important;
    @media (--md) {
      position: sticky;
      left: 0;
      top: var(--nav-height);
    }
  }

  .app-dataset--map {
    min-height: var(--vh-100, 100vh);
  }

  .app-dataset--map .app-dataset__filter-overview {
    @media (--md) {
      position: sticky;
      top: calc(var(--gap-600) + var(--gap-400));
      left: 0;
      z-index: 100;
    }
  }

  .app-dataset__filter-toggle {
    @media(--md) {
      display: none !important;
    }
  }

  .app-dataset__filter {
    @media(--md) {
      display: block !important;
    }
  }
</style>
