export default function (data) {
  // modifies filter form schema
  const schema = data.filter_options?.map(item => {
    if (item.$formkit === 'checkbox' && !item?.options) {
      return {
        ...item,
        'on-value': '1',
        'off-value': undefined,
      }
    }

    if (item.$formkit === 'select') {
      const nullOption = item.options.findIndex(({ value }) => value === '0' || value === '')
      if (nullOption && item.options[nullOption]?.value) {
        item.options[nullOption].value = undefined
      }
    }

    if (item.$formkit === 'radio') {
      item.options.forEach((option, i) => {
        if (typeof option.value === 'number') {
          item.options[i].value = option.value.toString()
        }
      })
    }

    if (item.$formkit === 'dateTimePicker') {
      item.$formkit = 'date'
    }

    if (item.$formkit === 'rangeSlider' && !item.value) {
      item.value = [ item.min, item.max ]
    }

    if (item.$formkit === 'rangeSlider' && !item.id) {
      item.id = item.name
    }

    if (item.name === 'compettion_regularity') {
      item.sectionsSchema = {
        suffix: {
          children: [
            {
              $el: 'span',
              attrs: {
                class: `marker-color-legend`,
              }
            }
          ]
        }
      }
    }

    if (item.name === 'has_city_architect') {
      item.sectionsSchema = {
        suffix: {
          children: [
            {
              $el: 'span',
              attrs: {
                class: `marker-color-legend`,
              }
            }
          ]
        }
      }
    }

    if (item.$formkit === 'text' && item.name === 'SearchableText') {
      item.suffixIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#E63E14" d="m22.327 17.417-4.442-4.442a9.526 9.526 0 0 0 .701-3.623C18.586 4.208 14.378 0 9.235 0 4.208 0 0 4.208 0 9.352c0 5.143 4.208 9.351 9.352 9.351 1.285 0 2.571-.234 3.623-.701l4.442 4.442c.702.701 1.637 1.052 2.455 1.052.935 0 1.754-.35 2.455-1.052 1.403-1.403 1.403-3.74 0-5.027ZM2.337 9.352c0-3.858 3.157-7.014 7.015-7.014 3.857 0 7.013 3.156 7.013 7.014 0 3.857-3.156 7.013-7.013 7.013-3.858 0-7.014-3.156-7.014-7.013ZM20.692 20.69a1.13 1.13 0 0 1-1.637 0l-3.974-3.975a10.832 10.832 0 0 0 1.636-1.636l3.975 3.974a1.13 1.13 0 0 1 0 1.637Z"/></svg>`
    }

    return item
  })

  return {
    schema,
  }
}
